<template>
  <v-dialog v-model="show" max-width="800">
    <v-card>
      <v-card-title>
        <DefaultTitle>Regulamin</DefaultTitle>
      </v-card-title>
      <v-card-text>
        <DefaultSubText>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
          tincidunt, purus vitae porttitor tristique, nisi ipsum faucibus justo,
          nec luctus sapien urna vel nisl. Nulla facilisi. Ut ultrices at libero
          vitae tincidunt. Aenean blandit, risus nec cursus eleifend, diam massa
          rutrum ipsum, vitae fermentum sem ex sed massa. Nulla sed augue lorem.
          Suspendisse potenti. Nullam blandit velit quis feugiat convallis.
          Donec iaculis diam diam, ut convallis mi gravida vitae. Duis ac
          ullamcorper odio. Cras venenatis nulla a eros lobortis, in bibendum mi
          egestas. Proin rutrum mauris sit amet ex varius auctor. Nulla
          facilisi. Praesent vel ultrices felis. Nunc vel blandit tortor.
          Maecenas consequat augue eu faucibus ultrices. Nulla ut mauris tempus
          est vestibulum interdum quis at odio. Aliquam pretium, lectus at
          venenatis gravida, tellus elit blandit neque, eget tempor nibh ipsum
          ut ante. Aliquam ultricies felis quis laoreet efficitur. Mauris sit
          amet ex quis nisi porttitor ultricies condimentum sed purus. Nunc non
          blandit urna. Vestibulum eu sapien eget tellus pharetra pulvinar. In
          hac habitasse platea dictumst. Nunc eget leo augue. Sed volutpat
          consequat imperdiet. Proin scelerisque nec eros nec tempus. Vivamus ut
          dignissim nisl, sit amet consectetur justo. Praesent imperdiet
          tristique lobortis. Duis feugiat ullamcorper pretium. Vestibulum in
          finibus diam, a pretium tortor. Phasellus non pellentesque magna.
          Proin nec viverra dui. Sed ac diam in sapien sollicitudin sodales sed
          vel lorem. Curabitur tincidunt interdum magna, interdum venenatis leo
          malesuada gravida. Curabitur tincidunt erat non congue tincidunt.
          Donec nec lorem tellus. Morbi semper commodo fermentum. Cras eget
          velit lacinia, porta orci nec, finibus neque. Duis ac feugiat neque.
          Aliquam mollis tortor nisi, ut consectetur leo vestibulum non.
          Maecenas laoreet laoreet ex, eget facilisis lacus semper eu. Mauris
          ornare porttitor elit eget bibendum. Sed sodales egestas est ut
          tincidunt. Maecenas ac turpis diam.
        </DefaultSubText>
      </v-card-text>
      <v-card-actions>
        <v-layout justify-end>
          <DefaultButton @click.prevent="show = false" primary>Zamknij</DefaultButton>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {}
  },
  components: {
    DefaultTitle: () => import("@/components/text/DefaultTitle"),
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>